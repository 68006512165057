import * as moment from 'moment';
import 'moment/locale/nb';
const newDate = (value: string | Date): Date => moment.utc(value, "YYYY-MM-DD").local().toDate()
const now = (): Date => moment.utc().local().toDate()
const isDateObj = (value: string | Date) => Object.prototype.toString.call(value) == '[object Date]';
const asLocaleStr = (value: string | Date) => {
    return moment.utc(value).local().toLocaleString()
}
const newDefaultDate = (): Date => {
    return moment.utc("1800-01-01T00:00:00").local().toDate()
}
const isDefaultDate = (value: string | Date) => {
    if (value == undefined) return true
    // console.log("IsDefault", value, moment(value).toDate().getTime() == moment("1800-01-01T00:00:00").toDate().getTime())
    try {
        const storedDate = moment.utc(value).local()
        const defaultDate = moment.utc("1800-01-01T00:00:00").local()
        if (storedDate.year() === defaultDate.year() || storedDate.year() === 1) return true
    } catch {
        return false;
    }
}
const toShortStr = (value: string | Date): string => {
    try {
        const converted = moment.utc(value).local().format("DD.MM.YYYY HH:mm")
        // console.log(value, converted)
        return converted
    } catch {
        return "ugyldig dato"
    }

}
const toShortDateStr = (value: string | Date): string => {
    try {
        const converted = moment.utc(value).local().format("DD.MM.YYYY")
        return converted
    } catch {
        return "ugyldig dato"
    }

}

const adjustDatePickerDate = (date: Date) => {
    date.setHours(date.getHours() + 2)
}
const dateInputString = (value: string | Date): string => {
    let useDate = value
    const defaultDate = isDefaultDate(value)
    // if (!value || defaultDate){
    //     useDate = now()
    // }
    if(defaultDate || useDate === "") return ""
    const converted = moment.utc(useDate).local().format("YYYY-MM-DD")
    return converted
}
const futureDateFormated = (howManyDays: number) => {
    const now = new Date()
    const adjusted = addDaysToDate(now, howManyDays).format("YYYY-MM-DD")
    return adjusted
}
const historicalDateFormated = (number: number, type: "days"|"weeks"|"year") => {
    const adjusted = getHistoricalDate(number, type).format("YYYY-MM-DD")
    return adjusted
}
const daysBetweenToDates = (newestDate: Date, oldestDate: Date) => {
    return moment.utc(newestDate).diff(moment.utc(oldestDate), "days")
}
const addDaysToDate = (date: Date, days: number) => {
    return moment.utc(date).add(days, "days")
}
const getHistoricalDate = (number: number, type: "days"|"weeks"|"year") => {
    return moment.utc(new Date()).subtract(number, type)
}
const isLegalYear = (date: Date) => {
    return moment.utc(date).year() > moment.utc(new Date()).subtract(10, "years").year()
}
class DateUtils {
    constructor() {
        moment.locale('nb')
    }
    newDate = newDate
    now = now
    newDefaultDate = newDefaultDate
    isDateObj = isDateObj
    asLocaleStr = asLocaleStr
    isDefaultDate = isDefaultDate
    toShortStr = toShortStr
    toShortDateStr = toShortDateStr
    adjustDatePickerDate = adjustDatePickerDate
    dateInputString = dateInputString
    futureDateFormated = futureDateFormated
    historicalDateFormated = historicalDateFormated
    daysBetweenToDates = daysBetweenToDates
    addDaysToDate = addDaysToDate
    isLegalYear = isLegalYear
}

export default new DateUtils()
