import { activeUser, userManager } from "../Auth";
import { ByggsokEventType } from "../utils/ByggsokEventType";
import { handleFetchError, handleFetchErrorV2 } from "./HandleFetchError";

export interface HttpResponse<T> extends Response {
    parsedBody?: T;
}
class AbApi {
    private _authMethodCookie: boolean
    public constructor(authMethodCookie: boolean) {
        this._authMethodCookie = authMethodCookie
    }
    async authheaders(path: string, xmlheader: boolean = false, ContentType: string = 'application/json') {
        let user = await activeUser()
        if (user?.expired) {
            user = await userManager.signinSilent()
            if (!user) await userManager.signinRedirect()
        }
        if (!user?.access_token) {
            var event = new CustomEvent("byggsokEvent", {
                detail: ByggsokEventType.LOGG_INN_PAA_NYTT
            });
            document.dispatchEvent(event);
        }
        var headers = new Headers();
        try {
            if (path.indexOf("://") > -1) {
                headers.append('Authorization', 'Bearer ' + user.access_token)
                if (xmlheader == false && ContentType == "application/json") {
                    headers.append('Content-Type', ContentType);
                }
                if (xmlheader)
                    headers.append('Content-Type', 'application/xml; charset=utf-8');
            }
        } catch (e) {
            var event = new CustomEvent("byggsokEvent", {
                detail: ByggsokEventType.LOGG_INN_PAA_NYTT
            });
            document.dispatchEvent(event);
            // ForceLogout()
        }

        return headers;
    }

    // async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {

    //     const res: Response = await fetch(dataUrl);
    //     const blob: Blob = await res.blob();
    //     return new File([blob], fileName);
    // }

    // base64ToArrayBuffer = (base64) => {
    //     //  console.log(binaryString)
    //     var binaryString = window.atob(base64);

    //     var binaryLen = binaryString.length;
    //     var bytes = new Uint8Array(binaryLen);
    //     for (var i = 0; i < binaryLen; i++) {
    //         var ascii = binaryString.charCodeAt(i);
    //         bytes[i] = ascii;
    //     }
    //     return bytes;
    // }

    // fetchAsBlob = async (url, name) => {
    //     var res = await fetch(url, { credentials: this._authMethodCookie ? "include" : "omit", method: 'get', headers: await this.authheaders(url, false, null) });
    //     let restext: string = await res.text();
    //     restext = restext.replace(/\"/g, "");
    //     //let response:Blob = await res.blob();
    //     return atob(restext);


    // }
    fetchAsByteArr = async (url: string) => {
        var res = await fetch(url, { credentials: this._authMethodCookie ? "include" : "omit", method: 'get', headers: await this.authheaders(url, false, null) });
        if (res?.ok)
            return await res.blob();
        handleFetchErrorV2(res)
    }
    // fetchAsByteArrAmbtia = async (url: string, apiKey: string) => {
    //     var res = await fetch(url, { method: 'get', headers: { "X-API-KEY": apiKey } });
    //     return await res.blob();
    // }
    // PostAsBlob = async (url, name, body: any) => {
    //     var res = await fetch(url, { credentials: this._authMethodCookie ? "include" : "omit", method: 'post', body: JSON.stringify(body), headers: await this.authheaders(url, false, "application/json") });
    //     let response: Blob = await res.blob();
    //     return response


    // }
    convertToPdf = async (url: string, params: any) => {
        var res = await fetch(url, { credentials: this._authMethodCookie ? "include" : "omit", method: 'post', headers: await this.authheaders(url, false, "application/json"), body: JSON.stringify(params) })
        if (res?.ok)
            return await res.blob();
        handleFetchError(res, url, "Kunne ikke konvertere til pdf. Vennligst kontakt Arkitektbedriftene")
    }



    async getNoAuth<T>(
        path: string,
        args: RequestInit = {
            credentials: this._authMethodCookie ? "include" : "omit", method: "get", headers: new Headers({ 'Content-Type': 'application/json' })
        }
    ): Promise<HttpResponse<T>> {
        return await this.http<T>(new Request(path, args));
    };



    async deleteReq<T>(
        path: string,
        args?: Partial<RequestInit>
    ): Promise<HttpResponse<T>> {
        return await this.http<T>(new Request(path, {
            credentials: this._authMethodCookie ? "include" : "omit",
            method: "delete",
            headers: await this.authheaders(path, false),
            ...args,
        }));
    };


    async get<T>(
        path: string,
        args?: Partial<RequestInit>
    ): Promise<HttpResponse<T>> {
        return await this.http<T>(new Request(path, {
            credentials: this._authMethodCookie ? "include" : "omit", method: "get", headers: await this.authheaders(path, false), ...args
        }));
    };


    async getxml(
        path: string,
        args?: Partial<RequestInit>
    ): Promise<HttpResponse<string>> {
        return await this.httpxml(new Request(path, {
            credentials: this._authMethodCookie ? "include" : "omit", method: "get", headers: await this.authheaders(path, true), ...args
        }));
    };



    async uploadfile<T>(
        path: string,
        body: any,
        args?: Partial<RequestInit>
    ): Promise<HttpResponse<T>> {
        return await this.http<T>(new Request(path, {
            credentials: this._authMethodCookie ? "include" : "omit", method: "post", body: body, headers: await this.authheaders(path, false, null), ...args
        }));
    };


    async post<T>(
        path: string,
        body: any,
        args?: Partial<RequestInit>
    ): Promise<HttpResponse<T>> {
        return await this.http<T>(new Request(path, {
            credentials: this._authMethodCookie ? "include" : "omit", method: "post", body: JSON.stringify(body), headers: await this.authheaders(path, false), ...args
        }));
    };

    async put<T>(
        path: string,
        body: any,
        args?: Partial<RequestInit>
    ): Promise<HttpResponse<T>> {
        return await this.http<T>(new Request(path, {
            credentials: this._authMethodCookie ? "include" : "omit", method: "put", body: JSON.stringify(body), headers: await this.authheaders(path, false), ...args
        }));
    };


    async http<T>(
        request: RequestInfo
    ): Promise<HttpResponse<T>> {

        try {
            const response: HttpResponse<T> = await fetch(
                request
            );
            try {
                // if (!response) throw new Error("Noe galt har skjedd")
                if (response?.ok || (response?.status === 400 && response?.url?.includes("api/maksproject"))) {
                    response.parsedBody = await response.json();
                } else if (response?.status === 401) {
                    var event = new CustomEvent("byggsokEvent", {
                        detail: ByggsokEventType.LOGG_INN_PAA_NYTT
                    });
                    document.dispatchEvent(event);
                }
                // else {
                //     handleFetchError(response, "")
                // }
            } catch (ex: any) {
                throw new Error(ex?.message);

            }
            return response;
        } catch (err: any) {
            if (err?.message === "Failed to fetch") {
                console.log({ err })
                // alert("Ikke kontakt med server " + err?.request?.url)
                throw new Error(`Et kall mot ${err?.request?.url} feilet med feilmelding: ${err?.message}`);
            }

        }

    }


    async httpxml<T>(
        request: RequestInfo
    ): Promise<HttpResponse<string>> {


        const response: HttpResponse<string> = await fetch(
            request
        );

        try {
            // may error if there is no body


            response.parsedBody = await response.text();
        } catch (ex) { }

        if (!response?.ok) {
            throw new Error(response?.statusText);
        }
        return response;
    }

}
export default AbApi
