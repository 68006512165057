import toast from "react-hot-toast";

interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

const text503 = "Noen av tjenestene er midlertidige utilgjengelige grunnet vedlikehold eller høy trafikk. Vennligst prøv igjen om noen minutter. Beklager for ulempen dette medfører."

export const handleFetchError = async (response: HttpResponse<unknown>, url?: string, tekst?: string) => {
    if (!response) throw new Error(tekst || "Noe gikk galt")
    let txt = ""
    if (response.bodyUsed) {
        txt = response.parsedBody as string
    } else
        txt = await response.text();
    try {
        if (txt) {

            let responseContent = await parseError(txt)
            if (responseContent === "" && response.statusText) {
                responseContent = response.statusText
            }
            if (responseContent === "") {
                switch (response.status) {
                    case 400:
                        responseContent = `400 - Ugyldig forespørsel`
                        break;
                    case 401:
                        responseContent = `401 - Ikke autentisert`
                        break;
                    case 403:
                        responseContent = `403 - Ikke tilgang`
                        break;
                    case 404:
                        responseContent = `404 - Fant ikke`
                        break;
                    case 500:
                        responseContent = `500 - Ukjent feil`
                        break;
                    case 503:
                        responseContent = `503 - Server ikke tilgjengelig`
                        break;
                    default:
                        responseContent = `${response.status}`
                }
            }
            throw new Error(responseContent + " " + url)
        }else{
            throw new Error(response?.statusText || "Noe gikk galt")
        }
    } catch (error) {
        var event = new CustomEvent("byggsokEvent", {
            detail: error
        });
        document.dispatchEvent(event);
    }

}
export const handleFetchErrorV2 = async (response: HttpResponse<unknown>) : Promise<string> => {
    
    if (!response || response.status === 503) return text503
    let txt = ""
    if (response.bodyUsed) {
        txt = response.parsedBody as string
    } else
        txt = await response.text();
    try {
        if (txt) {

            let responseContent = await parseError(txt)
            if (responseContent === "" && response.statusText) {
                responseContent = response.statusText
            }
            if (responseContent === "") {
                switch (response.status) {
                    case 400:
                        responseContent = `400 - Ugyldig forespørsel`
                        break;
                    case 401:
                        responseContent = `401 - Ikke autentisert`
                        break;
                    case 403:
                        responseContent = `403 - Ikke tilgang`
                        break;
                    case 404:
                        responseContent = `404 - Fant ikke`
                        break;
                    case 500:
                        responseContent = `500 - Ukjent feil`
                        break;
                    case 503:
                        responseContent = `503 - Server ikke tilgjengelig`
                        break;
                    default:
                        responseContent = `${response.status}`
                }
            }
            return response.url + "\n" + responseContent
            
        }else{
            return response.url + "\n" + (response?.statusText || "Noe gikk galt")
        }
    } catch (error) {
        var event = new CustomEvent("byggsokEvent", {
            detail: error
        });
        document.dispatchEvent(event);
    }

}
export const parseErrorAndToast = async (msg: string|any)  => {
    let str = await parseError(msg)
    if(str == undefined || str == "" || str == "{}")
        str = text503
    toast.error(str, { id: str })
}
export const parseError = async (msg: string|any) : Promise<string> => {
    let responseContent = msg
    try {
        let validJson = false
        try {
            JSON.parse(msg)
            validJson = true
        } catch { }
        const parsed = validJson ? JSON.parse(msg) : msg
        if(typeof parsed === "string") return parsed
        if (parsed.hasOwnProperty("title")) responseContent = parsed["title"] //Feks matrikkelController returnerer NotFound med title i JSON
        if (parsed.hasOwnProperty("Message")) responseContent = parsed["Message"] //Typisk MaksSok WebApi return
        if (parsed.hasOwnProperty("message")) responseContent = parsed["message"] //Typisk MaksSok WebApi return
    } catch { }
    return responseContent
}