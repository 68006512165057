import { ToastBar, ToastType, Toaster, useToaster, useToasterStore } from "react-hot-toast"
import colors from './styles/colors.module.scss'
import { FaExclamationCircle } from "react-icons/fa";
import { HiExclamation, HiLightBulb, HiInformationCircle } from "react-icons/hi";
import { HiCheckCircle, } from "react-icons/hi2";
import { Spinner } from "reactstrap";
import React, { useEffect } from "react";
import { useNotifications } from "./contexts/NotificationContextProvider";


const baseStyles: React.CSSProperties = {
    background: colors.toastBackground,
    color: colors.toastColor
}

const successStyles: React.CSSProperties = Object.assign({}, baseStyles, {

})
const errorStyles: React.CSSProperties = Object.assign({}, baseStyles, {

})
const loadingStyles: React.CSSProperties = Object.assign({}, baseStyles, {

})

const ToasterConfig = () => {
    // const { toasts } = useToasterStore();
    const {toasts} = useToaster()
    const notifications = useNotifications()
    useEffect(() => {
        const errors = toasts.filter(t => t.type === "error")
        if(errors.length > 0){
            errors.forEach(e => {notifications.errorToast(e)})
            
        }
    }, [toasts])
    // console.log("toasts", toasts)
    const [hover, setHover] = React.useState(false)
    const getIconColor = (type: ToastType) => {
        switch (type) {
            case "error":
                return colors.dangerIconColor
            case "success":
                return colors.successIconColor
            case "custom":
                return colors.warningIconColor
            default:
                return colors.infoIconColor
        }
    }
    const getIcon = (type: ToastType) => {
        const iconColor = getIconColor(type)
        switch (type) {
            case "loading":
                return <Spinner size={"sm"} style={{ color: iconColor }} />
            case "error":
                return <HiExclamation color={iconColor} style={{ color: iconColor }} />
            case "success":
                return <HiCheckCircle color={iconColor} style={{ color: iconColor }} />
            case "custom":
                return <FaExclamationCircle color={iconColor} style={{ color: iconColor }} />
            default:
                return <HiInformationCircle color={iconColor} style={{ color: iconColor }} />
        }
    }
    const getStyles = (type: ToastType) => {
        switch (type) {
            case "error":
                return errorStyles
            case "success":
                return successStyles
            case "loading":
                return loadingStyles
            default:
                return baseStyles
        }
    }
    return (
        <Toaster toastOptions={{
            ariaProps: {
                "aria-live": "polite",
                role: "status"
            },
            style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                minWidth: "300px",
                maxWidth: "800px",
                maxHeight: hover ? "100%" : "150px",
                height: "100%",
                overflowY: hover ? "auto" : "clip",
                margin: "0 30px"
            },
           
        }}>
            {(t) => {
                const {
                    type,
                    icon
                } = t
                if(type === "error"){
                    return
                }
                return <ToastBar toast={t} style={getStyles(type)}>
                    
                    {({ message }) => (
                        <div style={{ display: "flex", alignItems: hover ? "center" : "flex-start", padding: "0 10px" }}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <div style={{ flex: "0 0 20px", fontSize: "1.5em" }}>
                                {getIcon(type)}
                            </div>
                            <div>{message}</div>

                        </div>
                    )}
                </ToastBar>
            }}
        </Toaster>
    )
}

export default ToasterConfig;