import { version } from "react";
import { Omrader } from "../legacy/components/ansako/Ansvarsomrade";
import AbApi from "./AbApi";
import { Human, Project, SimpleCompany } from "../legacy/utilities/AB-Hub/Common/Model/Project";
import { GjennomforingsplanLinje } from "../legacy/utilities/AB-Hub/MAKSSok/Model/Gjennomforingsplanlinje";
import * as mmodel from "../legacy/utilities/AB-Hub/MAKSSok/Model/MAKSsok";
import { Altinn, GeoNorge } from "../interfaces/SharedTypes";
import { AbHubAzure } from "../interfaces/AbHubTypes";
import { server } from "../legacy/interfaces/types";
import { handleFetchErrorV2 } from "./HandleFetchError";
import toast from "react-hot-toast";

class MaksSokHubApi {
  api: AbApi
  constructor(api: AbApi){
    this.api = api
  }
  async getGeoNorgeRegister<T extends GeoNorge.GeoNorgeObject>(Server: string, area: string, register: string, subregister?: string) {
    let path = Server + "/api/geonorge/v2/" + area + "/" + register
    if(subregister) path = path+"/"+subregister
    const response = await this.api.get<GeoNorge.GeoNorgeResult<T>>(
      path
    );
    if (response?.parsedBody != null) {
      var types = response.parsedBody;
      var result = types.containeditems;
      result.sort((a, b) => a.label.localeCompare(b.label));
      types.containeditems = result;
      return types;
    }
    toast.error(await handleFetchErrorV2(response))
    return null;
  }

  async addToArWatch(Server: string, watch: mmodel.WatchAr) {
    const response = await this.api.post(Server + "/api/DibkStatus/watch", watch);
  }

  async getVedleggsopplysningerSkjema(Server: string, attachments: AbHubAzure.Attachment[]) : Promise<server.SkjemaSet> {
    const response = await this.api.post<server.SkjemaSet>(Server + "/api/Vedleggsopplysninger", attachments);
    if(response?.ok) return response.parsedBody
    toast.error(await handleFetchErrorV2(response))
    return null
  }
}
export default MaksSokHubApi
