import { useQuery } from "react-query";
import Api from "../Api";
import DateUtils from "../../utils/DateUtils";

export const pingMaksSok = () => {
    return fetch(`${Api.maksSokApiServer}/api/ping?timestamp=${DateUtils.now().getTime()}`,
        {
            credentials: "include"
        }
    )
}

export const usePingMaksSok = (enabled: boolean) => {
    return useQuery({
        queryKey: ["makssokping"],
        enabled: enabled,
        refetchInterval: 1000 * 5,
        queryFn: async () => {
            try {
                const res = await pingMaksSok()
                return res?.ok;
            } catch (error) {
                return false;
            }
        }
    })
}

