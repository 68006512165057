
const altinnProdEnv = process.env.REACT_APP_ALTINN_PROD_ENV;
export const AltinnEnv = {
    altinnApiKey: process.env.REACT_APP_ALTINN_API_KEY,
    altinnPath: process.env.REACT_APP_ALTINN_PATH,
    altinnProd: altinnProdEnv ? true : false,
    altinnRoles: altinnProdEnv ? [95, 28088, 11] : [134, 24448, 11],
    altinnPlanByggesakRole: altinnProdEnv ? 28088 : 24448,
    altinnProtectedRoles: altinnProdEnv ? [160, 125] : [195, 160],
    altinnManageRoles: altinnProdEnv ? [160, 125, 28997, 4] : [195, 160, 24869, 4],
    altinnSufficientRoles: altinnProdEnv ? [95, 28088, 11] : [134, 24448, 11],
}