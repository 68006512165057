import React from "react";
import { createBrowserRouter, Outlet, RouteObject } from "react-router-dom";
import AbSuspense from "./components/AbSuspense";

const AppContent = React.lazy(() => import('./AppContent'))
const Callback = React.lazy(() => import('./routes/protected/Callback.Route'))
const AltinnCallback = React.lazy(() => import('./routes/protected/AltinnCallback.Route'))
const Playground = React.lazy(() => import('./components/playground/Playground'))
// console.log("location", location.pathname.split("/"), location)
const routes: RouteObject[] = [

  {
    path: "*",
    element: <AbSuspense children={<AppContent />} />
  },
  {
    path: "/authCallback",
    element: <AbSuspense children={<Callback />} />,
  },{
    path: "/altinn/authCallback",
    element: <AbSuspense children={<AltinnCallback />} />,
  },
  {
    path: "/playground",
    element: <AbSuspense children={<Playground /> } />
  }
]
export const router = createBrowserRouter(routes);
