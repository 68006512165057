import { useEffect, useState } from "react"
import { Toast } from "react-hot-toast"
import { useLocation, useParams } from "react-router-dom"


export const useErrorToasts = () => {
    
    const [errorToasts, setErrorToasts] = useState<Toast[]>([])
    const [dismissedToasts, setDismissedToasts] = useState<string[]>([])
    
    useEffect(() => {
        document.addEventListener('popstate', () => {
            console.log(window.location.pathname)
        })
        
        
    }, [])
    useEffect(() => {
        if(dismissedToasts.length > 20){
            setDismissedToasts(dismissedToasts.slice(0, 10))
        }
    }, [dismissedToasts])
    const addErrorToast = (message: Toast) => {
        const exist = errorToasts.find(t => t.id === message.id || t.message === message.message)
        if (!exist && !dismissedToasts.includes(message.message.toString())) {
            setErrorToasts([...errorToasts, message])
            // if(message.duration){
            //     setTimeout(() => {
            //         removeErrorToast(message.id)
            //     }, message.duration)
            // }
        }
    }
    const removeErrorToast = (id: string) => {
        const toDelete = errorToasts.find(t => t.id === id)
        setErrorToasts(errorToasts.filter(t => t.id !== id))
        if (toDelete) {
            setDismissedToasts([...dismissedToasts, toDelete.message.toString()])
        }

    }
    const clearToasts = () => {
        setDismissedToasts([...dismissedToasts, ...errorToasts.map(t => t.message.toString())])
        setErrorToasts([])
    }
    return {
        errorToasts,
        addErrorToast,
        removeErrorToast,
        clearToasts
    }
}